/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyC8wO7ioE4MMQ3DBQWCOiBss-BDPcecjfo",
  "appId": "1:715381647613:web:17f3b957776386a2a93694",
  "authDomain": "schooldog-i-dodge-ga.firebaseapp.com",
  "measurementId": "G-4TB90RP6RE",
  "messagingSenderId": "715381647613",
  "project": "schooldog-i-dodge-ga",
  "projectId": "schooldog-i-dodge-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-dodge-ga.appspot.com"
}
